import Axios from 'axios'
import { extractOnlyNumbers } from '@/core/utils/formatter'

const buildSaveParams = empresa => ({
  razao_social: empresa.razaoSocial,
  cnpj: extractOnlyNumbers(empresa.cnpj),
  cpf: extractOnlyNumbers(empresa.cpf),
  caepf: extractOnlyNumbers(empresa.caepf),
  cnae_principal_codigo: extractOnlyNumbers(empresa.cnae),
  endereco_rua: empresa.endereco,
  endereco_numero: empresa.numero,
  endereco_bairro: empresa.bairro,
  endereco_id_cidade: empresa.cidade ? empresa.cidade.id_cidade : null,
  endereco_cep: extractOnlyNumbers(empresa.cep),
  status: empresa.status,
  tipo: empresa.tipo,
  id_matriz: empresa.idMatriz,
  id_organizacao: empresa.idOrganizacao,
  grau_risco: empresa.grauRisco,
  permitir_agendamento: empresa.permitirAgendamento,
  faturar_matriz: empresa.faturar_matriz || false,
  enable_agendamento_express: empresa.enable_agendamento_express,
  plano: {
    id_plano_padrao: empresa.plano.id,
    qtde_assinaturas: empresa.plano.tipo === 'assinatura' ? empresa.plano.qtde_assinaturas : null,
    email_faturamento: empresa.plano.email_faturamento,
    dia_vencimento: empresa.plano.dia_vencimento,
    checkout_agendamento: empresa.plano.checkout_agendamento,
    faturar_matriz: empresa.faturar_matriz || false,
  },
  hide_matriz: empresa.hide_matriz,
  show_matriz_sso: empresa.show_matriz_sso,
  copy_setores_funcoes: empresa.copy_setores_funcoes,
  id_soc: empresa.id_soc,
})

export default {
  update: empresa => {
    const params = buildSaveParams(empresa)
    return Axios.put(`/empresas/${empresa.id}`, params)
  },
  insert: empresa => {
    const params = buildSaveParams(empresa)
    return Axios.post('/empresas', params)
  },
  search: (semUnidades = false) => {
    if (!semUnidades) {
      return Axios.get('/empresas')
    }

    return Axios.get('/empresas', {
      params: {
        sem_unidades: semUnidades,
      },
    })
  },
  searchCnaes: () => Axios.get('/empresas/cnaes/listagem'),
  getSso: idEmpresa => Axios.get(`/empresas/${idEmpresa}/sso`),
  searchPaginated: params => Axios.get('/empresas/paginada', { params }),
  findByCnpj: (cnpj, params = null) => Axios.get(`/empresas/cnpj/${cnpj}`, { params }),
  findByCpf: cpf => Axios.get(`/empresas/cpf/${cpf}`),
  findById: idEmpresa => Axios.get(`/empresas/${idEmpresa}`),
  findByName: nomeEmpresa => Axios.get(`/empresas/nome/${nomeEmpresa}`),
  getFuncionariosByEmpresaId: idEmpresa => Axios.get(`/empresas/${idEmpresa}/funcionarios`),
  // prettier-ignore
  movimentacao: (cnpj, dataInicio, dataFinal) => Axios.get(`/empresas/${cnpj}/movimentacao`, { params: { dataInicio, dataFinal } }),
  gerarPcmso: (idsEmpresas, pcmso) => Axios.post('/pcmso', { idsEmpresas, pcmso }),
  inativarPcmso: (idPcmso, idEmpresa) => Axios.put('pcmso/inativar', { idPcmso, idEmpresa }),
  gerarPgr: (idsEmpresas, pgr) => Axios.post('/pgr', { idsEmpresas, pgr }),
  inativarPgr: (idPgr, idEmpresa) => Axios.put('pgr/inativar', { idPgr, idEmpresa }),
  gerarLtcat: (idEmpresa, ltcat) => Axios.post(`/ltcat/empresa/${idEmpresa}`, ltcat),
  showLtcat: (idLtcat, idEmpresa) => Axios.get(`/ltcat/${idLtcat}/empresa/${idEmpresa}`),
  showLtcatLegado: (idLtcat, idEmpresa) => Axios.get(`/ltcatLegado/${idLtcat}/empresa/${idEmpresa}`),
  inativarLtcat: (idLtcat, idEmpresa) => Axios.put('ltcat/inativar', { idLtcat, idEmpresa }),
  getPlano: idEmpresa => Axios.get(`/empresas/${idEmpresa}/plano`),
  getContrato: idEmpresa => Axios.get(`/empresas/${idEmpresa}/show-contrato`),
  updatePlano: (idEmpresa, plano) => {
    const preco_plano = plano.preco_plano.replace(',', '.')
    const desconto = plano.desconto ? plano.desconto.replace(',', '.') : null
    const dt_vencimento = plano.dt_vencimento?.split('/').reverse().join('-')

    return Axios.put(`/empresas/${idEmpresa}/plano`, { ...plano, preco_plano, desconto, dt_vencimento })
  },
  createPlano: (idEmpresa, plano) => {
    const preco_plano = plano.preco_plano.replace(',', '.')
    const desconto = plano.desconto ? plano.desconto.replace(',', '.') : null
    const dt_vencimento = plano.dt_vencimento?.split('/').reverse().join('-')

    return Axios.post(`/empresas/${idEmpresa}/plano`, { ...plano, preco_plano, desconto, dt_vencimento })
  },
  syncPlano: ({ idEmpresa, plano }) => {
    return Axios.put(`/empresas/${idEmpresa}/sync-plano`, { ...plano })
  },
  clonarProtocolo: ({ idEmpresaDestino, idEmpresaOrigem, importarProtocoloSimilares }) =>
    Axios.post('/empresas/protocolos/clonar', { idEmpresaDestino, idEmpresaOrigem, importarProtocoloSimilares }),
  exportarProtocolo: ({ idEmpresaOrigem, idsEmpresasDestino, exportarProtocoloSimilares }) =>
    Axios.post('/empresas/protocolos/exportar', { idEmpresaOrigem, idsEmpresasDestino, exportarProtocoloSimilares }),
  importXlsxFuncionarios: data => Axios.post('/empresas/funcionarios/importar-xlsx', data),
  importJsonFuncionarios: funcionarios => Axios.post('/empresas/funcionarios/importar-json', { funcionarios }),
  importXlsxUnidades: data => Axios.post('/empresas/unidades/importar-xlsx', data),
  importJsonUnidades: unidades => Axios.post('/empresas/unidades/importar-json', { unidades }),
  converterEmpresasParaFilial: (idEmpresa, idEmpresasAMigrar) =>
    Axios.patch(`/empresas/${idEmpresa}/converter-filiais`, { empresas: idEmpresasAMigrar }),
  converterFiliaisParaMatrizes: (idEmpresa, idFiliaisAMigrar) =>
    Axios.patch(`/empresas/${idEmpresa}/converter-matrizes`, { ids: idFiliaisAMigrar }),
}

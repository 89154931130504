import Axios from 'axios'
import { extractOnlyNumbers, formatDate } from '@/core/utils/formatter'

export default {
  search: (idFuncionario = null, idCidade = null, idEmpresa = null, examesProtocolo = null) =>
    Axios.get('/clinicas', {
      params: {
        funcionario: idFuncionario,
        cidade: idCidade,
        empresa: idEmpresa,
        examesProtocolo,
      },
    }),
  getAgendamentos: (token, per_page, filtros, options) => {
    const filtroParams = {}

    filtros.forEach(filtro => (filtroParams[filtro.column] = filtro.value))

    let { sortDesc, sortBy } = options
    sortBy = sortBy[0] ? sortBy[0] : 'id_aso'
    sortDesc = sortDesc[0] ? 'asc' : 'desc'

    const { itemsPerPage } = options
    per_page = itemsPerPage
    return Axios.get(`/clinicas/${encodeURIComponent(token)}/agendamentos`, {
      params: { page: options.page, per_page, order_by: sortBy, order: sortDesc, ...filtroParams },
    })
  },
  finalizarAso: (token, idAso, formData) => {
    return Axios.post(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/finalizar`, formData)
  },
  finalizarAsoHibrido: (token, idAso, formData) => {
    return Axios.post(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/finalizar-hibrido`, formData)
  },
  salvarAnamnese: (token, idAso, anamnese) => {
    return Axios.post(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/salvar-anamnese`, {
      anamnese,
    })
  },
  finalizarAsoHai: (token, idAso, formData) => {
    return Axios.post(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/finalizar-hai`, formData)
  },
  getResultadosComplementares: (token, idAso) => {
    return Axios.get(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/resultados-complementares`)
  },
  definirHorario: ({ id_aso, dt_agendamento, orientacoes }, token) =>
    Axios.patch(`/clinicas/${encodeURIComponent(token)}/asos/${id_aso}/definir-horario`, {
      dt_agendamento: formatDate(dt_agendamento),
      orientacoes,
    }),
  openFichaClinica: (token, idAso) =>
    Axios.get(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/ficha-clinica/download`),
  openAso: (token, idAso) => Axios.get(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/arquivo-retorno/download`),
  mapeamentoDeClinicas: (filtro, page, per_page) => {
    return Axios.get('/clinicas/mapeamento', { params: { filtro, page, per_page } })
  },
  clinicasFilterAutocomplete: filtro => {
    return Axios.get('/clinicas/filterAutocomplete', { params: { filtro } })
  },
  findByCnpj: cnpj => Axios.get(`/admin/clinicas/cnpj/${extractOnlyNumbers(cnpj)}`),
  getAnamnese: (token, idAso) => Axios.get(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/anamnese`),
  getInformacoesAtendimentoOcupacional: (token, idAso) =>
    Axios.get(`/clinicas/${encodeURIComponent(token)}/asos/${idAso}/informacoes-ocupacional`),
}

import Axios from 'axios'
import { extractOnlyNumbers, formatDate } from '@/core/utils/formatter'

const deepCopy = obj => JSON.parse(JSON.stringify(obj))

const buildSaveFuncionarioAgendamento = funcionario => ({
  ...funcionario,
  cpf: extractOnlyNumbers(funcionario.cpf),
  cbo: funcionario.cbo.split('-')[0].trim(),
  dt_contratacao: formatDate(funcionario.dt_contratacao),
  dt_agendamento: funcionario.dt_agendamento,
  dt_nascimento: formatDate(funcionario.dt_nascimento),
  exame: funcionario.exame,
  exames: funcionario.exames.filter(exame => !exame.unavailable).map(exame => exame.id_exame),
  funcao: funcionario.funcao,
  nome: funcionario.nome,
  riscos: funcionario.riscos.map(risco => risco.id_risco),
  setor: funcionario.setor,
  sexo: funcionario.sexo,
  id_horario: funcionario.idHorario,
})

export default {
  getInfoAgendamento: (idFuncionario, tipoExame) =>
    Axios.get(`/funcionarios/${idFuncionario}/agendamento/${tipoExame}`),
  getAgendamentos: idFuncionario => Axios.get(`/funcionarios/${idFuncionario}/agendamentos`),
  agendamentosIndisponiveis: idFuncionario => Axios.get(`/funcionarios/${idFuncionario}/agendamentos/indisponiveis`),
  getAgendaMedica: (data, idFuncionario) => Axios.get(`/horarios/${data}/funcionario/${idFuncionario}`),
  openAso: idAso => Axios.get(`/asos/${idAso}/arquivo-retorno/download`),
  buscaCidades: ({ nome, countClinicas }) => Axios.get('/cidades', { params: { nome, countClinicas } }),
  verificaCpf: cpf => Axios.get(`/novo-agendamento/verifica-cpf/${extractOnlyNumbers(cpf)}`),
  buscaCbos: (search, idFuncao) => Axios.get('/novo-agendamento/cbos', { params: { search, idFuncao } }),
  buscaFuncoes: (nome, idEmpresa) => Axios.get('/novo-agendamento/funcoes', { params: { nome, idEmpresa } }),
  buscaSetores: (nome, idEmpresa) => Axios.get('/novo-agendamento/setores', { params: { nome, idEmpresa } }),
  buscaRiscos: () => Axios.get('/novo-agendamento/riscos'),
  protocolo: (exame, funcao, cbo, setor, questionario, idClinica) =>
    Axios.get(`/novo-agendamento/protocolo/${exame}`, {
      params: { funcao, cbo, setor, questionario, idClinica },
    }),
  buscaExames: (idClinica, idEmpresa) => Axios.get('/novo-agendamento/exames', { params: { idClinica, idEmpresa } }),
  mountPrecoClinica: (idEmpresa, idClinica, examesDisponiveis) => {
    return Axios.get('/novo-agendamento/mount-preco-clinica', {
      params: { idEmpresa, idClinica, examesDisponiveis },
    })
  },
  buscaHorarios: data => Axios.get(`/novo-agendamento/horarios/${data}`),
  finalizarAgendamento: agendamento => {
    const agendamentoCopy = deepCopy(agendamento)

    agendamentoCopy.funcionarios = agendamentoCopy.funcionarios.map(
      funcionario => buildSaveFuncionarioAgendamento(funcionario)
      // eslint-disable-next-line function-paren-newline
    )

    return Axios.post('/novo-agendamento/finalizar', agendamentoCopy)
  },
  enviarGuias: async ({ id, email }) => {
    try {
      const params = { id, email }
      return Axios.post('/novo-agendamento/guias/enviar', params)
    } catch (e) {
      return Promise.reject(e)
    }
  },
}

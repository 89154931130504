import Axios from 'axios'
import formatter from '@/core/utils/formatter'

const buildPessoaParams = params => ({
  nome: params.nome,
  cpf: formatter.extractOnlyNumbers(params.cpf),
  rg: formatter.extractOnlyNumbers(params.rg),
  dt_nascimento: formatter.formatDate(params.dt_nascimento),
  sexo: params.sexo,
})

const buildPresencialParams = params => ({
  pessoa: buildPessoaParams(params.pessoa),
  agendamento: {
    dt_agendamento: params.agendamento.dt_agendamento ? formatter.formatDate(params.agendamento.dt_agendamento) : null,
    id_clinica: params.agendamento.clinica.id_clinica,
  },
  funcao: params.funcao.id_funcao,
  setor: params.setor.id_setor,
})

const buildTelemedicinaParams = params => ({
  pessoa: buildPessoaParams(params.pessoa),
  agendamento: { online: true, id_horario: params.agendamento.id_horario },
  funcao: params.funcao.id_funcao,
  setor: params.setor.id_setor,
  uploads: params.uploads,
})

export default {
  save: (params, exameOnline = false) => {
    const data = exameOnline ? buildTelemedicinaParams(params) : buildPresencialParams(params)

    const { id_funcionario, exame } = params.agendamento

    return Axios.post(`/funcionarios/${id_funcionario}/aso/${exame}`, data)
  },
  update: aso => {
    return Axios.put(`/asos/${aso.idAso}`, aso)
  },
  getAsos: (page, perPage, filtros, options, admin) => {
    const filtroParams = {}
    filtros.forEach(filtro => (filtroParams[filtro.column] = filtro.value))

    let { sortDesc, sortBy } = options
    sortBy = sortBy[0] ? sortBy[0] : 'id_aso'
    sortDesc = sortDesc[0] ? 'desc' : 'asc'

    const { itemsPerPage } = options
    perPage = itemsPerPage

    return Axios.get('/asos', {
      params: { page, perPage, sortBy, order: sortDesc, admin, ...filtroParams },
    })
  },
  getAso: idAso => Axios.get(`/asos/${idAso}`),
  cancelarAso: idAso => Axios.patch(`/asos/${idAso}/cancelar`),
  ativarAso: idAso => Axios.patch(`/asos/${idAso}/ativar`),
  reenviarEmailClinica: idAso => Axios.post(`/asos/${idAso}/reenviar-email-clinica`),
  getResultadosComplementares: idAso => Axios.get(`/asos/${idAso}/resultados-complementares`),
  onBulkInativar: idsAsos => Axios.patch('/asos/inativar-massa', { idsAsos }),
  onBulkAtivar: idsAsos => Axios.patch('/asos/ativar-massa', { idsAsos }),
  onBulkNaoCompareceu: idsAsos => Axios.patch('/asos/nao-compareceu', { idsAsos }),
  onBulkCancelarFinalizacao: idsAsos => Axios.patch('/asos/cancelar-finalizacao', { idsAsos }),
  reverterCancelarFinalizacao: idAso => Axios.patch(`/asos/${idAso}/reverter-cancelar-finalizacao`),
}

import autenticacaoService from '@/services/autenticacao'

export const SAVE_SESSION = 'SAVE_SESSION'
export const SET_CUSTOMIZACAO = 'SET_CUSTOMIZACAO'

//! MOCK ACL
const permissions = {
  Limer: [
    'createFuncaoSetorNovoAgendamento',
    'accessBeneficios',
    'disableAgendamentoNovo',
    'accessEmpresas',
    'accessAdministracao',
    'accessEsocial',
    'accessDocumentosSSO',
  ],
  Unimed: ['accessRiscos', 'accessUsuarios', 'accessMedicos'],
}

const state = {
  username: '',
  email: null,
  tipoUsuario: null,
  funcaoSetorPermission: null,
  enableEmpresaCreation: null,
  blockUsersCreation: null,
  disableFuncionarios: null,
  enableIndicadores: null,
  enableCertificadoDigital: null,
  enableMedicos: null,
  enableESocial: null,
  enableAtestado: null,
  enableMigrarFuncionario: null,
  enableVisualizarContrato: null,
  enableFaturamento: null,
  enableConverterEmpresas: null,
  parceiro: null,
  enableUnidadeCreation: null,
  enableMudancaSenha: null,
  enableRedeAtendimento: null,
  enableFinanceiro: null,
  isApoiar: null,
  enableCustomizacao: null,
  enableTelemedicina: null,
  urlLogo: null,
  colorTheme: null,
  enableUsuarios: null,
  carteiraDigital: null,
  showPrecoAgendamento: null,
}

const mutations = {
  [SAVE_SESSION]: (state, user) => {
    state.id_usuario = user.id_usuario
    state.username = user.usuario
    state.tipoUsuario = user.tipo_usuario
    state.funcaoSetorPermission = user.funcao_setor_permission
    state.enableEmpresaCreation = user.enable_create_empresas
    state.blockUsersCreation = user.block_create_users
    state.disableFuncionarios = user.disable_funcionarios
    state.disableAgendamentos = user.disable_agendamentos
    state.disableAgendamentoNovo = user.disable_agendamento_novo
    state.enableIndicadores = user.enable_indicadores
    state.enableRedeAtendimento = user.enable_rede_atendimento
    state.enableUsuarios = user.enable_usuarios
    state.enableCertificadoDigital = user.enable_certificado_digital
    state.enableESocial = user.enable_esocial
    state.enableAtestados = user.enable_atestados
    state.email = user.email
    state.enableMigrarFuncionario = user.enable_migrar_funcionario
    state.enableVisualizarContrato = user.enable_visualizar_contrato
    state.enableFaturamento = user.enable_faturamento
    state.enableMedicos = user.enable_medicos
    state.enableConverterEmpresas = user.enable_converter_empresas
    state.isFromVero = user.is_from_vero
    state.isFromBelfort = user.is_from_belfort
    state.isFromSemework = user.is_from_semework
    state.isApoiar = user.is_apoiar
    state.enableInventarioRiscos = user.enable_inventario_riscos
    state.showPrecoAgendamento = user.show_preco_agendamento
    state.parceiro = user.parceiro
    state.idParceiro = user.id_parceiro
    state.certificadosExpirados = user.certificados_expirados
    state.enableUnidadeCreation = user.enable_create_unidades
    state.enableMudancaSenha = user.enable_mudanca_senha
    state.enableFinanceiro = user.enable_financeiro
    state.enableCustomizacao = user.enable_customizacao
    state.enableTelemedicina = user.enable_telemedicina
    state.enableLinksCadastro = user.enable_links_cadastro
    state.urlLogo = user.customizacao?.url_logo || '/plataforma-limer.png'
    state.colorTheme = user.customizacao?.color_theme || '#006bf2'
    state.carteiraDigital = user.carteira_digital
    state.workspace = user.workspace
    state.permissions = user.permissions
  },
  [SET_CUSTOMIZACAO]: (state, { urlLogo, colorTheme, workspace }) => {
    state.urlLogo = urlLogo || '/plataforma-limer.png'
    state.colorTheme = colorTheme || '#006bf2'
    state.workspace = workspace || 'Limer'
  },
}

const getters = {
  id_usuario: state => state.id_usuario,
  username: state => state.username,
  email: state => state.email,
  tipoUsuario: state => state.tipoUsuario,
  funcaoSetorPermission: state => state.funcaoSetorPermission,
  enableEmpresaCreation: state => state.enableEmpresaCreation,
  blockUsersCreation: state => state.blockUsersCreation,
  disableFuncionarios: state => state.disableFuncionarios,
  disableAgendamentos: state => state.disableAgendamentos,
  disableAgendamentoNovo: state => state.disableAgendamentoNovo,
  enableIndicadores: state => state.enableIndicadores,
  enableUsuarios: state => state.enableUsuarios,
  enableCertificadoDigital: state => state.enableCertificadoDigital,
  enableMedicos: state => state.enableMedicos,
  enableESocial: state => state.enableESocial,
  enableAtestados: state => state.enableAtestados,
  enableMigrarFuncionario: state => state.enableMigrarFuncionario,
  enableVisualizarContrato: state => state.enableVisualizarContrato,
  enableFaturamento: state => state.enableFaturamento,
  enableConverterEmpresas: state => state.enableConverterEmpresas,
  isFromVero: state => state.isFromVero,
  isFromBelfort: state => state.isFromBelfort,
  isFromSemework: state => state.isFromSemework,
  isApoiar: state => state.isApoiar,
  enableInventarioRiscos: state => state.enableInventarioRiscos,
  showPrecoAgendamento: state => state.showPrecoAgendamento,
  parceiro: state => state.parceiro,
  certificadosExpirados: state => state.certificadosExpirados,
  idParceiro: state => state.idParceiro,
  enableUnidadeCreation: state => state.enableUnidadeCreation,
  enableMudancaSenha: state => state.enableMudancaSenha,
  enableRedeAtendimento: state => state.enableRedeAtendimento,
  enableFinanceiro: state => state.enableFinanceiro,
  enableCustomizacao: state => state.enableCustomizacao,
  enableTelemedicina: state => state.enableTelemedicina,
  enableLinksCadastro: state => state.enableLinksCadastro,
  urlLogo: state => state.urlLogo,
  colorTheme: state => state.colorTheme,
  carteiraDigital: state => state.carteiraDigital,
  workspace: state => state.workspace,
  permissions: state => state.permissions,
}

const actions = {
  login: async ({ commit }, { username, password }) => {
    try {
      const response = await autenticacaoService.login(username, password)
      autenticacaoService.saveCookie(response.data.token)

      commit(SAVE_SESSION, { ...response.data.user, permissions: permissions[response.data.user.workspace] })
      return Promise.resolve(true)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  logout: async ({}, router) => {
    await autenticacaoService.logout()
    if (router) {
      // if router exists redirect to unauthorized
      router.push({ name: 'sessionExpired' })
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

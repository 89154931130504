import Axios from 'axios'
import formatter from '@/core/utils/formatter'

const formatParams = params => ({
  ...params,
  cpf: formatter.extractOnlyNumbers(params.cpf),
  rg: formatter.extractOnlyNumbers(params.rg),
  pis_pasep: formatter.extractOnlyNumbers(params.pis_pasep),
  dt_nascimento: formatter.formatDate(params.dt_nascimento),
  dt_contratacao: formatter.formatDate(params.dt_contratacao),
  dt_ultimo_aso: formatter.formatDate(params.dt_ultimo_aso),
})

export default {
  insert: async funcionario => {
    const params = formatParams(funcionario)

    return Axios.post('/funcionarios', params)
  },
  list: (page, perPage, filtros, options, admin) => {
    const filtroParams = {}

    filtros.forEach(filtro => (filtroParams[filtro.column] = filtro.value))

    let { sortDesc, sortBy } = options
    sortBy = sortBy[0] ? sortBy[0] : 'id_funcionario'
    sortDesc = sortDesc[0] ? 'desc' : 'asc'

    const { itemsPerPage } = options
    perPage = itemsPerPage

    return Axios.get('/funcionarios', {
      params: { page, perPage, sortBy, order: sortDesc, admin, ...filtroParams },
    })
  },
  update: async funcionario => {
    const params = formatParams(funcionario)

    return Axios.put(`/funcionarios/${funcionario.id_funcionario}`, params)
  },
  search: async data => {
    const { page, perPage, column, relation, desc, filters } = data

    const body = { filters, sort: { relation, column, desc } }
    const params = { page, perPage }

    return Axios.post('/funcionarios/list', body, { params })
  },
  findById: async id => Axios.get(`/funcionarios/${id}`),
  // eslint-disable-next-line arrow-body-style
  toggleStatusFuncionario: (idFuncionario, status) => {
    return Axios.patch(`/funcionarios/${idFuncionario}/${status ? 'ativar' : 'inativar'}`)
  },
  toggleStatusFuncionariosBulk: (ids, action) => Axios.patch(`/funcionarios/${action}`, { ids }),
  migrarFuncionario: funcionario => {
    return Axios.post('/funcionarios/migrar', funcionario)
  },
  criarCatFuncionario: (id, formularioCat) => Axios.post(`/funcionarios/${id}/cat`, formularioCat),
  editarCatFuncionario: (id, formularioCat) => Axios.put(`/funcionarios/${id}/cat`, formularioCat),
  getCatsFuncionario: id => Axios.get(`/funcionarios/${id}/cat`),
  deletarCatFuncionario: id => Axios.delete(`/funcionarios/${id}/cat`),
  generateCatPDF: (idCat, idFuncionario, idEmpresa) =>
    Axios.post(`/funcionarios/${idFuncionario}/cat/${idCat}/generate-pdf`, { idEmpresa }, { responseType: 'blob' }),
  getItemsCat: () => Axios.get('/funcionarios/cat/get-items'),
  mountProtocolo: (idEmpresa, funcao, setor, idFuncao, idSetor) =>
    Axios.get('/funcionarios/mount-protocolo', { params: { idEmpresa, funcao, setor, idFuncao, idSetor } }),
}

// import autenticacaoService from '@/services/autenticacao'
import empresa from '../empresa'
import funcionarios from './funcionarios'

export const SET_STEP = 'SET_STEP'
export const SET_CIDADE = 'SET_CIDADE'
export const SET_CLINICA = 'SET_CLINICA'
export const SET_CNPJ = 'SET_CNPJ'
export const SET_CLINICA_HORARIOS = 'SET_CLINICA_HORARIOS'
export const SET_CLINICA_HORARIO_AGENDADO = 'SET_CLINICA_HORARIO_AGENDADO'
export const RESET_STATE = 'RESET_STATE'
export const SET_TIPO_AGENDAMENTO = 'SET_TIPO_AGENDAMENTO'
export const SET_MESSAGE_TS = 'SET_SLACK'
export const SET_TIPO_PAGAMENTO = 'SET_TIPO_PAGAMENTO'

const getDefaultState = () => {
  return {
    cidade: null,
    clinica: null,
    clinica_horarios: null,
    clinica_horario_agendado: false,
    tipoAgendamento: 'presencial',
    tipoPagamento: null,
  }
}

const state = getDefaultState()

const mutations = {
  [SET_STEP]: (state, step) => (state.actualStep = step),
  [SET_CIDADE]: (state, cidade) => (state.cidade = cidade),
  [SET_CLINICA]: (state, clinica) => (state.clinica = clinica),
  [SET_CLINICA_HORARIOS]: (state, horarios) => (state.clinica_horarios = horarios),
  [SET_CLINICA_HORARIO_AGENDADO]: (state, agendado) => (state.clinica_horario_agendado = agendado),
  [RESET_STATE]: state => Object.assign(state, getDefaultState()),
  [SET_TIPO_AGENDAMENTO]: (state, value) => (state.tipoAgendamento = value),
  [SET_TIPO_PAGAMENTO]: (state, tipoPagamento) => (state.tipoPagamento = tipoPagamento),
}

const getters = {
  cidade: state => state.cidade,
  clinica: state => state.clinica,
  clinica_horarios: state => state.clinica_horarios,
  clinica_horario_agendado: state => state.clinica_horario_agendado,
  tipoAgendamento: state => state.tipoAgendamento,
  getTipoPagamento: state => state.tipoPagamento,
}

const actions = {
  resetAgendamento: ({ commit }) => {
    commit('funcionarios/resetState')
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {
    empresa,
    funcionarios,
  },
}

const getDefaultState = () => {
  return {
    funcionarios: [],
    currentFuncionarioCpf: null,
  }
}

const state = getDefaultState()

const mutations = {
  addFuncionario: (state, funcionario) => state.funcionarios.push(funcionario ?? funcionario),
  editFuncionario: (state, { funcionario, index }) => (state.funcionarios[index] = funcionario),
  deleteFuncionario: (state, index) => state.funcionarios.splice(index, 1),
  resetState: state => Object.assign(state, getDefaultState()),
  updateEmpresaFaturaPaga(state, { id_empresa, idsAsos, faturaPaga }) {
    state.funcionarios.forEach(funcionario => {
      const empresaIdMatch = funcionario.empresa?.id_empresa === id_empresa
      const unidadeIdMatch = funcionario.unidade?.id_empresa === id_empresa

      if (empresaIdMatch) {
        funcionario.empresa = {
          idsAsos,
          ...funcionario.empresa,
          faturaPaga,
        }
      }

      if (unidadeIdMatch) {
        funcionario.unidade = {
          idsAsos,
          ...funcionario.unidade,
          faturaPaga,
        }
      }
    })
  },
}

const getters = {
  getFuncionarios: state => state.funcionarios,
  getFuncionariosPorEmpresa: state => {
    return state.funcionarios.reduce((acc, curr) => {
      const empresa = `${curr.empresa.razao_social} - ${curr.empresa.cnpj}`
      if (!acc[empresa]) {
        acc[empresa] = []
      }
      acc[empresa].push(curr)
      return acc
    }, {})
  },
  getCurrentFuncionarioIndex: state =>
    state.funcionarios.findIndex(funcionario => funcionario.cpf === state.currentFuncionarioCpf),
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
